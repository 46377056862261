import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { AppBar, Collapse, Icon, IconButton, Toolbar } from '@material-ui/core';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    Link,
    NavLink,
  } from 'react-router-dom';

 

const useStyles = makeStyles({
    title: {
        fontFamily: 'Ibarra Real Nova',
        fontWeight: 'bold',
        fontSize: '2rem',
    },
    root: {
        background: 'rgba(0,0,0,0.5)',
    },
    description: {
        fontFamily: 'Ibarra Real Nova',
        fontWeight: 'bold',
        fontSize: '2rem',
    }
});


export default function ImageCard({place, checked}) {
    const classes = useStyles();
  return (
    <Collapse in={checked} {...(checked ? {timeout: 2000}: {})}>
    <Card className={classes.root} sx={{ maxWidth: 645, boxShadow: 10, background:'rgba(0,0,0,0.5)', margin:'20px',  }}>
      <CardMedia
        component="img"
        height="440"
        image={place.imageUrl}
        alt="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h1"  sx={{fontFamily: 'Ibarra Real Nova', fontWeight:'bold', fontSize:'2rem', color:'#fff' }}>
          {place.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" className={classes.description} sx={{fontFamily: 'Ibarra Real Nova', fontSize:'1.1rem', color:'#fff' }}>
          {place.description}
        </Typography>
      </CardContent>
      <CardActions>
            <Button variant="contained" href={place.link} size="small">{place.buttonText}</Button>
      </CardActions>
    </Card>
    
    </Collapse>
  );
}
