import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageCard from './imageCard';
import places from '../static/places';
import useWindowPosition from '../hook/useWindowPosition';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down("md")]:{
            flexDirection:"column",
        }
    },
}));

export default function () {
  const classes = useStyles();
  const checked = useWindowPosition('header');
  return <div className={classes.root} id='place-to-visit2'>
    <ImageCard place={places[2]} checked={checked}/>
    <ImageCard place={places[3]} checked={checked}/>
  </div>; 
}