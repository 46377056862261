import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Collapse, Icon, IconButton, Toolbar } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link as Scroll } from 'react-scroll';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faTwitter,
    faInstagram
  } from "@fortawesome/free-brands-svg-icons";

const useStyles = makeStyles((theme) => ({
    root: {
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '50vh',
        fontFamily: 'Ibarra Real Nova',
    },
   AppBar: {
        background: 'none',
        fontFamily: 'Ibarra Real Nova',
    },
    appBarWrapper: {
        width:'80%',
        margin:'0 auto',
    },
    appBarTitle: {
        flexGrow: '1',
    },
    farthestColor: {
        color: '#000',
    },
    loteColor: {
        color: '#879745',
    },
    treeColor: {
        color:'#6e5d4c',
    },
    title: {
        color:'#fff',
        fontSize: '4.5rem',
        textAlign: 'center',
   },
   root2: {
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: 'Ibarra Real Nova',
},
    title2: {
    color:'#000',
    fontSize: '1.2rem',
    textAlign: 'center',
    borderRadius: '15px',
    padding: '10px',
    margin: '0 auto',
    width: '150vh',
    marginTop: '-2%',
},   
   downIcon: {
    color:'#fff',
    fontSize: '4.5rem',
    textAlign: 'center',
},
    goDown: {
        color: '#000',
        fontSize: '4rem',

    },
      
      facebook: {
        color: '#4968ad',
        margin: '0 1rem',
        display: 'inline-block',
    },
      
      twitter: {
        color: '#49a1eb',
        margin: '0 1rem',
        display: 'inline-block',
    },
      
      instagram: {
        color: 'black',
        margin: '0 1rem',
        display: 'inline-block',
      },


}));

export default function Header() {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  useEffect(()=> {
    setChecked(true);
  }, [])
  return (
    <div id='header'>
        
        <AppBar class={classes.AppBar} elevation={0}>
            <Toolbar className={classes.appBarWrapper}>
                <h1 className={classes.appBarTitle}>
                    {/* <span className={classes.farthestColor}>Farthest</span>
                    <span className={classes.loteColor}>Lote</span>
                    <span className={classes.treeColor}>Tree</span> */}
                    <img style={{marginTop:20}} src={process.env.PUBLIC_URL + "/assets/fltficon.png"} alt='Farthest Lote Tree Foundation' width={'350vh'} />
                    <h5 style={{marginLeft:97}}>Reimagining Islamic Education</h5>                    
                </h1>
                {/* <img src={process.env.PUBLIC_URL + "/assets/fltficon.png"} alt='Farthest Lote Tree Foundation' width={'350vh'} /> */}
                <a href="https://www.facebook.com/FarthestLoteTree/" className={classes.facebook}>
                    <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
                <a href="https://www.twitter.com/FarthestTree" className={classes.twiiter}>
                    <FontAwesomeIcon icon={faTwitter} size="2x" />
                </a>
                <a href="https://www.instagram.com/thefarthestlotetree" className={classes.instagram}>
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
            </Toolbar>
        </AppBar>


        <Collapse in={checked} {...(checked ? {timeout: 2000} : {})} collapsedHeight={100}>
            <div className={classes.root}>
                <h1 className={classes.title}>
                    Welcome to the <br /> Farthest Lote Tree
                </h1> 
            </div>
            <div className={classes.root2}> 
                <h4 className={classes.title2}>The Farthest Lote Tree Foundation is dedicated to reimagining Islamic education to convey the richness of Islamic civilizations in multicultural environments. We are a 501c3 nonprofit organization that relies on community support for all of our activities and productions. Based in Del Mar, CA. Est. 2021.</h4> 
            </div>
            <div className={classes.downIcon}>
                <Scroll to='place-to-visit' smooth={true}>
                    <IconButton>
                        <ExpandMoreIcon className={classes.goDown}/>
                    </IconButton>
                </Scroll>
            </div>
        </Collapse>


    </div>
  )
}