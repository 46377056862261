import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import Header from './components/Header';
import PlaceToVisit from './components/placeToVisit';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
} from 'react-router-dom';
import Form from './components/emailForm';
import PlaceToVisit2 from './components/placeToVisit2';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/Page_5.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',  
  },
}));

export default function App() {
  const classes = useStyles();
  return <div className={classes.root}>
    <CssBaseline />
    <Header />
    <PlaceToVisit />
    <PlaceToVisit2 />
  </div>; 
}