const places = [
    {
        title: 'The Life of Imam Hussain',
        description: 'An immersive multimedia platform that delves into the story, meaning, and philosophy of the life of Imam Hussain. It includes a Qur\'an and hadith portal relating to Imam Hussain as well as a narrative on his life story and a bookstore with original FLTF publications.',
        imageUrl: process.env.PUBLIC_URL + '/assets/lifeOfImamThumbnail.jpeg',
        time: 1500,
        link: 'https://www.lifeofimam.com/',
        buttonText: 'Enter',
    },
    {
        title: 'Donate Here',
        description: 'We are a 501c3 nonprofit organization based in Del Mar, CA. We rely on community support for all of our activities and productions. All donations are tax deductible. We greatly appreciate your support in producing high quality Islamic educational materials!',
        imageUrl: process.env.PUBLIC_URL + '/assets/Cover.png',
        time: 1500,
        link: 'https://www.paypal.com/donate/?hosted_button_id=2KBLVBJUR3LPE',
        buttonText: 'Donate',
    },
    {
        title: 'Sign up for Free Workbooks',
        description: 'Our published books come with a free workbook/study guide. To redeem your free PDF copy, click the button below!',
        imageUrl: process.env.PUBLIC_URL + '/assets/Page_2.png',
        time: 1500,
        link: '#',
        buttonText: 'Coming Soon',
    },
    {
        title: 'Join our Email List',
        description: 'To stay informed of future products, events, news, and more, sign up for our mailing list using the button below!',
        imageUrl: process.env.PUBLIC_URL + '/assets/fltficon23.png',
        time: 1500,
        link: 'http://globalshiism.com/sendy/subscription?f=gnya2GbzYPXTJn0QIdMabu763ulEUnfyWC763A45wuj763dTN1v1e4F3itk5q763H4892tqHu2',
        buttonText: 'Join',
    },
 
];

export default places;